import React from "react";
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const blogPosts = [
  {
    id: "1",
    title: {
      es: "Automatización de tareas financieras con IA: Ahorra más del 60% en costos operativos",
      en: "Automating Financial Tasks with AI: Save Over 60% in Operating Costs",
    },
    excerpt: {
      es: "La automatización de procesos financieros con inteligencia artificial, como las soluciones de Mantis, permite a las empresas optimizar operaciones, reducir costos, minimizar errores y mejorar la toma de decisiones estratégicas, aumentando así su eficiencia y competitividad en un entorno dinámico.",
      en: "The automation of financial processes with artificial intelligence, such as Mantis solutions, allows companies to optimize operations, reduce costs, minimize errors, and improve strategic decision-making, thus increasing their efficiency and competitiveness in a dynamic environment.",
    },
    date: "2024-12-01",
  },
];

const translations = {
  en: {
    nav: {
      features: "Features",
      workflow: "Workflow",
      security: "Security",
      pricing: "Pricing",
      contact: "Contact",
      login: "Login",
      startFreeTrial: "Start Free Trial",
      blog: "Blog",
    },
    footer: {
      product: "Product",
      company: "Company",
      resources: "Resources",
      legal: "Legal",
      aboutUs: "About Us",
      careers: "Careers",
      blog: "Blog",
      documentation: "Documentation",
      support: "Support",
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      cookiePolicy: "Cookie Policy",
      copyright: "© 2024 Mantis AI. All rights reserved.",
    },
  },
};

export default function Blog() {
  const navigate = useNavigate();
  const t = translations.en;

  return (
    <div className="min-h-screen bg-background">
      <Navbar t={t} />
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center my-10">
          Mantis Blog
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogPosts.map((post) => (
            <Card key={post.id} className="flex flex-col p-6">
              <CardContent className="flex-grow p-6">
                <h2 className="text-2xl font-semibold mb-2">{post.title.en}</h2>
                <p className="text-muted-foreground mb-4">{post.excerpt.en}</p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-muted-foreground">
                    {post.date}
                  </span>
                  <Button
                    variant="outline"
                    onClick={() => navigate(`/blog/${post.id}`)}
                  >
                    Read More
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </main>
      <Footer t={t} />
    </div>
  );
}
