import React, { useState, useEffect, useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useInView,
  AnimatePresence,
} from "framer-motion";
import {
  ArrowRight,
  Moon,
  Sun,
  Mail,
  HardDrive,
  Cloud,
  MessageSquare,
  FileText,
  Settings,
  Shield,
  Lock,
  Users,
  LogIn,
  Menu,
  X,
  ChevronDown,
  CheckCircle2,
  Globe,
} from "lucide-react";
import { backUrl } from "../utils/backURL";
// Import custom components (you'll need to create these)
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import { Input } from "./components/ui/Input";
import { Textarea } from "./components/ui/textarea";
import { Label } from "./components/ui/label";

// Import images
import Feature1 from "./img/Feature 1.png";
import Feature2 from "./img/Feature 2.png";
import Feature3 from "./img/Feature 3.png";
import Feature4 from "./img/Feature 4.png";
import mockup from "./img/CAPTURA DE MANTIS.png";
import mockup2 from "./img/MOCKUP 2.png";
import gmailLogo from "./img/gmail.png";
import driveLogo from "./img/Drive.png";
import mantisFavicon from "./img/favicon.ico";
import onedriveLogo from "./img/Onedrive.png";
import whatsappLogo from "./img/Whattsapp.png";
import "./styles.output.css";
import { ThemeToggle } from "./components/ui/ThemeToggle";

const socialNetworks = [
  {
    name: "Gmail",
    logo: gmailLogo,
    description: "Automate email processing and response handling",
  },
  {
    name: "Google Drive",
    logo: driveLogo,
    description: "Streamline document management and file organization",
  },
  {
    name: "OneDrive",
    logo: onedriveLogo,
    description: "Seamless Microsoft ecosystem integration",
  },
  {
    name: "WhatsApp",
    logo: whatsappLogo,
    description: "Automate customer communication at scale",
  },
];

const workflowSteps = [
  {
    title: "No need for tech skill",
    description:
      "You're all set to use Mantis and create automations just by chatting",
    icon: Feature1,
  },
  {
    title: "Fast and clear ROI",
    description:
      "Feel the impact within days, with reductions in operational time, costs, and financial losses",
    icon: Feature2,
  },
  {
    title: "Access new insights",
    description:
      "Unstructured data is hard to visualize; Mantis not only makes it possible but also automates the process",
    icon: Feature3,
  },
  {
    title: "Get full control",
    description:
      "You can view the results of the automation in a tailored control panel to monitor and track your workflows",
    icon: Feature4,
  },
];

const securityFeatures = [
  {
    title: "Secured cloud",
    description:
      "Rest assured that your data is safe in a secure, closed cloud ecosystem. You enjoy the results while we handle the entire infrastructure",
    icon: Cloud,
  },
  {
    title: "Unique SSO",
    description:
      "We tailor our security to your needs, ensuring that information meets the requirements to keep your compliance on track",
    icon: HardDrive,
  },
  {
    title: "Audit every move",
    description:
      "We provide logs for tracking all data movements, ensuring accountability and transparency",
    icon: Shield,
  },
  {
    title: "Role and permits",
    description:
      "Gain full control over information access by setting up roles, groups, and permissions",
    icon: Lock,
  },
];

const flowFeatures = [
  {
    title: "Reconciliation",
    description:
      "Review pictures, PDF statements, and emails to keep your finances on track and prevent losses. Get insights in real time",
    icon: "📊",
  },
  {
    title: "Expense categorization",
    description:
      "Automatically categorize expenses from receipts, invoices, and emails to keep your finances organized and spot savings opportunities",
    icon: "💰",
  },
  {
    title: "PQRD automation",
    description:
      "Identify user communication intents and respond automatically in compliance, using information within your organization",
    icon: "🤖",
  },
  {
    title: "Document validation",
    description:
      "Validate documents with accuracy and compliance while streamlining processes, providing real-time feedback to users or suppliers",
    icon: "✅",
  },
];

function InfiniteLogoCarousel() {
  const [duplicatedNetworks, setDuplicatedNetworks] = useState([]);
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    setDuplicatedNetworks([
      ...socialNetworks,
      ...socialNetworks,
      ...socialNetworks,
    ]);
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-background/50 py-12">
      <div
        className={`flex ${
          isMobile ? "animate-scroll-mobile" : "animate-scroll"
        } space-x-16 w-[${isMobile ? "800%" : "200%"}]`}
      >
        {duplicatedNetworks.map((network, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-[300px] flex-shrink-0"
          >
            <img
              src={network.logo}
              alt={network.name}
              className="w-20 h-20 object-contain mb-4"
            />
            <h3 className="text-lg font-semibold mb-2">{network.name}</h3>
            <p className="text-sm text-muted-foreground text-center">
              {network.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function FadeInSection({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

function NavItem({ href, children }) {
  return (
    <a
      href={href}
      className="text-sm font-medium text-muted-foreground hover:text-foreground transition-colors"
    >
      {children}
    </a>
  );
}

function MobileNav({ isOpen, setIsOpen, children, t }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100%" }}
          transition={{ type: "spring", bounce: 0, duration: 0.4 }}
          className="fixed inset-y-0 right-0 w-full sm:max-w-sm bg-background p-6 sm:ring-1 sm:ring-gray-900/10 max-h-[15vh] overflow-y-auto"
        >
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Mantis AI</span>
              <img className="h-8 w-auto" src={mantisFavicon} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="md:flex items-center space-x-6 pt-6">
                <NavItem
                  href="#features"
                  title={t.nav.features}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.features}
                </NavItem>
                <NavItem
                  href="#workflow"
                  title={t.nav.workflow}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.workflow}
                </NavItem>
                <NavItem
                  href="#security"
                  title={t.nav.security}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.security}
                </NavItem>
                <NavItem
                  href="#pricing"
                  title={t.nav.pricing}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.pricing}
                </NavItem>
                <NavItem
                  href="#contact"
                  title={t.nav.contact}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.contact}
                </NavItem>
                <NavItem
                  href="blog"
                  title={t.nav.blog}
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.blog}
                </NavItem>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

/* function LanguageToggle({ language, onLanguageChange }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="rounded-full">
          <Globe className="h-5 w-5" />
          <span className="sr-only">Toggle language</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => onLanguageChange("en")}>
          English
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onLanguageChange("es")}>
          Español
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onLanguageChange("pt")}>
          Português
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
} */

const translations = {
  en: {
    hero: {
      title: "Automate Finance and Compliance Workflows with AI Solutions",
      subtitle:
        "Build AI-powered workflows in seconds, cut 60% of costs, work 20x faster, and eliminate errors",
    },
    features: {
      title: "Create workflows just from chatting",
      subtitle:
        "Describe what are the tasks and operations you expect Mantis to execute using the files you have",
    },
    workflow: {
      title: "Automate workflows that were manual before",
      subtitle:
        "We are talking about automating email reading, extracting data from PDFs, inputting data into a spreadsheet, building a dashboard, or executing manual tasks of a big process.",
    },
    security: {
      title: "Uncompromising Security",
      subtitle:
        "Your data's security is our top priority. We employ state-of-the-art measures to ensure your information remains protected.",
    },
    pricing: {
      title: "Simple, Transparent Pricing",
      subtitle:
        "Choose the plan that fits your needs. All plans come with a 14-day free trial.",
      free: "Free",
      basic: "Basic",
      pro: "Pro",
      enterprise: "Enterprise",
      month: "/ month",
      custom: "Custom",
      workflows: "workflows",
      repetitions: "repetitions",
      integrations: "integrations",
      users: "users",
      dashboard: "Dashboard",
      contactSales: "Contact Sales",
      startFreeTrial: "Start Free Trial",
    },
    contact: {
      title: "Get in Touch",
      subtitle:
        "Have questions? We're here to help. Reach out to us and we'll get back to you as soon as possible.",
      name: "Your Name",
      email: "Your Email",
      message: "Your Message",
      send: "Send Message",
      sending: "Sending...",
    },
    nav: {
      features: "Features",
      workflow: "Workflow",
      security: "Security",
      pricing: "Pricing",
      contact: "Contact",
      login: "Login",
      startFreeTrial: "Start Free Trial",
      blog: "Blog",
    },
    footer: {
      product: "Product",
      company: "Company",
      resources: "Resources",
      legal: "Legal",
      aboutUs: "About Us",
      careers: "Careers",
      blog: "Blog",
      documentation: "Documentation",
      support: "Support",
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      cookiePolicy: "Cookie Policy",
      copyright: "© 2024 Mantis AI. All rights reserved.",
    },
  },
  es: {
    // Spanish translations (same structure as English)
  },
  pt: {
    // Portuguese translations (same structure as English)
  },
};

export default function LandingPage({ onLanguageChange = () => {} }) {
  const [theme, setTheme] = useState("light");
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const [sendingWaitlistContact, setSendingWaitlistContact] = useState(false);
  const [waitlistContact, setWaitlistContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const t = translations[language];

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    onLanguageChange(newLang);
  };

  const handleCreateWaitlistContact = async (e) => {
    e.preventDefault();
    console.log({
      name: waitlistContact.name,
      email: waitlistContact.email,
      message: waitlistContact.message,
    });
    if (sendingWaitlistContact) return;
    setSendingWaitlistContact(true);
    const req = await fetch(`${backUrl}/waitlistContacts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: waitlistContact.name,
        email: waitlistContact.email,
        message: waitlistContact.message,
      }),
    });

    const res = await req.json();
    if (res.success) {
      alert("Message sent successfully");
    } else {
      alert("Error sending message");
    }
  };

  return (
    <div className="min-h-screen bg-background text-foreground">
      {/* <div className="fixed top-4 right-4 z-50">
        <LanguageToggle
          language={language}
          onLanguageChange={handleLanguageChange}
        />
      </div> */}
      <nav className="fixed w-full bg-background/80 backdrop-blur-sm border-b border-border z-50 shadow-md">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-lg bg-primary/90 flex items-center justify-center">
                <img
                  src={mantisFavicon}
                  alt="Logo"
                  className="w-6 h-6 object-cover"
                />
              </div>
              <span className="ml-2 text-xl font-bold">Mantis AI</span>
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <NavItem
                href="#features"
                title={t.nav.features}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.features}
              </NavItem>
              <NavItem
                href="#workflow"
                title={t.nav.workflow}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.workflow}
              </NavItem>
              <NavItem
                href="#security"
                title={t.nav.security}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.security}
              </NavItem>
              <NavItem
                href="#pricing"
                title={t.nav.pricing}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.pricing}
              </NavItem>
              <NavItem
                href="#contact"
                title={t.nav.contact}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.contact}
              </NavItem>
              <NavItem
                href="blog"
                title={t.nav.blog}
                className="px-4 py-2 text-lg font-semibold hover:text-primary transition-colors"
              >
                {t.nav.blog}
              </NavItem>
            </div>

            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  //Open login frontend
                  window.open("https://mantisapp.vercel.app", "_blank");
                }}
                className="hidden md:inline-flex"
              >
                <LogIn className="mr-2 h-4 w-4" />
                {t.nav.login}
              </Button>
              {/* <LanguageToggle
                language={language}
                onLanguageChange={handleLanguageChange}
              /> */}
              {/* <Button
                variant="ghost"
                size="icon"
                onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                className="rounded-full"
              >
                <Sun className="h-5 w-5 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                <Moon className="absolute h-5 w-5 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                <span className="sr-only">Toggle theme</span>
              </Button> */}
              <ThemeToggle />
              <div className="md:hidden">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Menu className="h-6 w-6" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} t={t}>
        {/* <div className="py-6">
          <Button className="w-full" onClick={() => {}}>
            {t.nav.startFreeTrial}
          </Button>
        </div> */}
      </MobileNav>

      <main className="pt-24 pb-16">
        {/* Hero Section */}
        <section className="py-12 overflow-hidden">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <div className="text-center relative z-10">
                <motion.h1
                  className="text-4xl sm:text-6xl font-bold tracking-tight mb-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.8 }}
                >
                  {t.hero.title}
                </motion.h1>
                <motion.p
                  className="mt-4 text-xl text-muted-foreground max-w-2xl mx-auto text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.8 }}
                >
                  {t.hero.subtitle}
                </motion.p>
              </div>
            </FadeInSection>
            <motion.div
              className="mt-16 relative"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.8, duration: 1 }}
            >
              <div className="relative mx-auto max-w-4xl">
                {/* <img
                  src={mockup2}
                  alt="Mantis AI Dashboard"
                  className="w-full h-auto rounded-xl shadow-2xl"
                /> */}
                <iframe
                  src="https://www.youtube.com/embed/xqcdcvRpgU0?si=7ssOSiVwBFnVg80m"
                  title="Creating Automated Reconciliation Workflows with Mantis 🤖"
                  className="w-full rounded-xl shadow-2xl"
                  height={450}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <div className="absolute -top-4 -left-4 w-72 h-72 bg-primary/30 rounded-full filter blur-3xl opacity-50 animate-blob"></div>
                <div className="absolute -bottom-4 -right-4 w-72 h-72 bg-secondary/30 rounded-full filter blur-3xl opacity-50 animate-blob animation-delay-2000"></div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Logo Carousel */}
        <InfiniteLogoCarousel />

        {/* Features Section */}
        <section id="features" className="py-24 bg-accent/5">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <h2 className="text-3xl font-bold tracking-tight text-center mb-12">
                {t.features.title}
              </h2>
            </FadeInSection>
            <FadeInSection>
              <motion.p
                className="mt-4 text-xl text-muted-foreground max-w-2xl mx-auto text-center mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
              >
                {t.features.subtitle}
              </motion.p>
              <div className="relative mx-auto max-w-4xl">
                <img
                  src={mockup}
                  alt="Mantis AI Mockup"
                  className="w-full h-auto rounded-xl shadow-2xl"
                />
                <div className="absolute -top-4 -right-4 w-72 h-72 bg-primary/30 rounded-full filter blur-3xl opacity-50 animate-blob"></div>
                <div className="absolute -bottom-4 -left-4 w-72 h-72 bg-secondary/30 rounded-full filter blur-3xl opacity-50 animate-blob animation-delay-2000"></div>
              </div>
            </FadeInSection>
          </div>
        </section>

        <section className="py-4 bg-background">
          <div className="container px-4 mx-auto">
            <div className="mb-16">
              <div className="flex justify-center">
                <div className="w-full">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {" "}
                    {/* Updated here */}
                    {flowFeatures.map((feature) => (
                      <Card
                        key={feature.title}
                        className="p-6 hover:shadow-lg transition-shadow"
                      >
                        <div className="flex flex-col h-full">
                          <div className="text-3xl mb-4">{feature.icon}</div>
                          <h3 className="text-xl font-semibold mb-2">
                            {feature.title}
                          </h3>
                          <p className="text-muted-foreground flex-grow">
                            {feature.description}
                          </p>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Workflow Steps */}
        <section id="workflow" className="py-24">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <div className="text-center mb-16">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-4">
                  {t.workflow.title}
                </h2>
                <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
                  {t.workflow.subtitle}
                </p>
              </div>
            </FadeInSection>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {workflowSteps.map((step, index) => (
                <FadeInSection key={step.title}>
                  <div className="text-center group cursor-pointer">
                    <div className="mb-6 mx-auto flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      <img
                        src={step.icon}
                        alt={step.title}
                        className="w-40 h-40 object-contain"
                      />
                    </div>
                    <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                    <p className="text-muted-foreground">{step.description}</p>
                  </div>
                </FadeInSection>
              ))}
            </div>
          </div>
        </section>

        {/* Security Section */}
        <section id="security" className="py-24 bg-accent/5">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-4">
                  {t.security.title}
                </h2>
                <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
                  {t.security.subtitle}
                </p>
              </div>
            </FadeInSection>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {securityFeatures.map((feature, index) => (
                <FadeInSection key={feature.title}>
                  <Card className="hover:shadow-lg transition-shadow duration-300">
                    <CardContent className="flex flex-col items-center text-center p-6">
                      <div className="mb-4 mt-4 w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center">
                        {React.createElement(feature.icon, {
                          className: "h-8 w-8 ",
                        })}
                      </div>
                      <h3 className="text-xl font-semibold mb-2">
                        {feature.title}
                      </h3>
                      <p className="text-muted-foreground">
                        {feature.description}
                      </p>
                    </CardContent>
                  </Card>
                </FadeInSection>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="py-24">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-4">
                  {t.pricing.title}
                </h2>
                <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
                  {t.pricing.subtitle}
                </p>
              </div>
            </FadeInSection>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                t.pricing.free,
                t.pricing.basic,
                t.pricing.pro,
                t.pricing.enterprise,
              ].map((plan) => (
                <FadeInSection key={plan}>
                  <Card className="hover:shadow-lg transition-shadow duration-300">
                    <CardContent className="p-6">
                      <h3 className="text-2xl font-bold mb-4 mt-4 text-left">
                        {plan}
                      </h3>
                      <h4 className="text-4xl font-bold mb-4 text-left">
                        {plan === t.pricing.free
                          ? "$0"
                          : plan === t.pricing.basic
                          ? "$250"
                          : plan === t.pricing.pro
                          ? "$750"
                          : t.pricing.custom}
                        {plan !== t.pricing.enterprise && (
                          <span className="text-base font-normal">
                            {t.pricing.month}
                          </span>
                        )}
                      </h4>
                      <ul className="space-y-2 mb-6">
                        <li className="flex items-center">
                          <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                          {plan === t.pricing.free || plan === t.pricing.basic
                            ? "1"
                            : plan === t.pricing.pro
                            ? "3"
                            : t.pricing.custom}{" "}
                          {plan === t.pricing.free || plan === t.pricing.basic
                            ? "workflow"
                            : t.pricing.workflows}
                        </li>
                        {plan !== t.pricing.free && (
                          <li className="flex items-center">
                            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                            {plan === t.pricing.basic
                              ? "1"
                              : plan === t.pricing.pro
                              ? "2"
                              : t.pricing.custom}{" "}
                            {plan === t.pricing.basic
                              ? "integration"
                              : t.pricing.integrations}
                          </li>
                        )}
                        <li className="flex items-center">
                          <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                          {plan === t.pricing.free
                            ? "5"
                            : plan === t.pricing.basic
                            ? "100"
                            : plan === t.pricing.pro
                            ? "500"
                            : t.pricing.custom}{" "}
                          {plan === t.pricing.free
                            ? "repetitions"
                            : t.pricing.repetitions}
                        </li>
                        <li className="flex items-center">
                          <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                          {plan === t.pricing.free
                            ? "1"
                            : plan === t.pricing.basic
                            ? "3"
                            : plan === t.pricing.pro
                            ? "5"
                            : t.pricing.custom}{" "}
                          {plan === t.pricing.free ? "user" : t.pricing.users}
                        </li>
                        {plan === t.pricing.pro && (
                          <li className="flex items-center">
                            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                            1 {t.pricing.dashboard}
                          </li>
                        )}
                      </ul>
                      <Button
                        className="w-full"
                        variant={plan === t.pricing.pro ? "default" : "outline"}
                        onClick={() => {
                          //Go to contact section
                          document.getElementById("contact").scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                      >
                        {plan === t.pricing.enterprise
                          ? t.pricing.contactSales
                          : t.pricing.startFreeTrial}
                      </Button>
                    </CardContent>
                  </Card>
                </FadeInSection>
              ))}
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-24 bg-accent/5">
          <div className="container px-4 max-w-screen-xl mx-auto">
            <FadeInSection>
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-4">
                  {t.contact.title}
                </h2>
                <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
                  {t.contact.subtitle}
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <Card className="max-w-md mx-auto">
                <CardContent className="p-6">
                  <form className="space-y-4 mt-4">
                    <div className="text-left">
                      <Label htmlFor="name">{t.contact.name}</Label>
                      <Input
                        id="name"
                        placeholder={t.contact.name}
                        value={waitlistContact.name}
                        onChange={(e) =>
                          setWaitlistContact({
                            ...waitlistContact,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="text-left">
                      <Label htmlFor="email">{t.contact.email}</Label>
                      <Input
                        id="email"
                        type="email"
                        placeholder={t.contact.email}
                        value={waitlistContact.email}
                        onChange={(e) =>
                          setWaitlistContact({
                            ...waitlistContact,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="text-left">
                      <Label htmlFor="message">{t.contact.message}</Label>
                      <Textarea
                        id="message"
                        placeholder={t.contact.message}
                        rows={4}
                        value={waitlistContact.message}
                        onChange={(e) =>
                          setWaitlistContact({
                            ...waitlistContact,
                            message: e.target.value,
                          })
                        }
                      />
                    </div>
                    <Button
                      onClick={(e) => handleCreateWaitlistContact(e)}
                      className="w-full"
                    >
                      {sendingWaitlistContact
                        ? t.contact.sending
                        : t.contact.send}
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </FadeInSection>
          </div>
        </section>
      </main>

      <footer className="py-12 bg-accent/5 border-t">
        <div className="container px-4 max-w-screen-xl mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-semibold mb-4">{t.footer.product}</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#features"
                    title={t.nav.features}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.nav.features}
                  </a>
                </li>
                <li>
                  <a
                    href="#workflow"
                    title={t.nav.workflow}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.nav.workflow}
                  </a>
                </li>
                <li>
                  <a
                    href="#pricing"
                    title={t.nav.pricing}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.nav.pricing}
                  </a>
                </li>
                <li>
                  <a
                    href="blog"
                    title={t.nav.blog}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.nav.blog}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">{t.footer.company}</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.aboutUs}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.careers}
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    title={t.nav.contact}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.nav.contact}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">{t.footer.resources}</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.blog}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.documentation}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.support}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-4">{t.footer.legal}</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.termsOfService}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.privacyPolicy}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {t.footer.cookiePolicy}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t text-center text-muted-foreground">
            <p>{t.footer.copyright}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
