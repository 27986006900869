import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const blogPosts = {
  1: {
    title: {
      es: "Automatización de tareas financieras con IA: Ahorra más del 60% en costos operativos",
      en: "Automating Financial Tasks with AI: Save Over 60% in Operating Costs",
    },
    date: "2024-12-01",
    content: {
      es: `
        <p class="mb-4 text-muted-foreground">En un entorno empresarial cada vez más competitivo, las compañías buscan constantemente maneras de optimizar sus operaciones. La automatización de procesos financieros es una de las estrategias más efectivas para reducir costos, mejorar la eficiencia y minimizar los errores. Las tareas repetitivas y manuales, como la contabilidad, la facturación y la gestión de pagos, a menudo consumen tiempo valioso y son propensas a errores humanos. Implementar herramientas de automatización financiera puede transformar estos procesos, permitiendo que las empresas ahorren tiempo y dinero mientras mejoran la precisión de sus datos.</p>
        <p class="mb-4 text-muted-foreground">La automatización financiera no solo acelera los procesos, sino que también garantiza una mayor precisión en las transacciones y reportes. Esto es fundamental en el mundo financiero, donde la toma de decisiones depende de datos correctos y oportunos. Gracias a la integración de tecnologías avanzadas, como la inteligencia artificial (IA), las empresas pueden optimizar sus flujos de trabajo y, además, aprender de patrones históricos para predecir y adaptar los procesos de forma inteligente. Esto se traduce en un aumento de la eficiencia operativa y una mejor capacidad de respuesta ante cambios en el mercado y las regulaciones.</p>
        <p class="mb-4 text-muted-foreground">Un ejemplo destacado de la automatización con IA en el sector financiero es Mantis, una empresa especializada en la optimización de procesos mediante inteligencia artificial. Mantis ayuda a las empresas a eliminar las tareas manuales y repetitivas, ahorrando más del 60% en costos operativos. Con la implementación de soluciones inteligentes, Mantis no solo mejora la eficiencia interna, sino que también reduce el riesgo de errores humanos, lo que lleva a un flujo de trabajo más confiable y rentable. La automatización con Mantis ofrece una ventaja competitiva crucial, especialmente en mercados donde la rapidez y precisión son fundamentales.</p>
        <p class="mb-4 text-muted-foreground">En resumen, la automatización de procesos financieros mediante inteligencia artificial es clave para mejorar la productividad, reducir costos y aumentar la competitividad. Las empresas que implementan soluciones como las ofrecidas por Mantis pueden optimizar sus operaciones, tomar decisiones basadas en datos precisos y adaptarse rápidamente a las demandas del mercado. Al integrar herramientas de automatización financiera, las compañías no solo reducen sus costos, sino que también mejoran la calidad de sus servicios y la toma de decisiones estratégicas.</p>
      `,
      en: `
        <p class="mb-4 text-muted-foreground">In an increasingly competitive business environment, companies are constantly looking for ways to optimize their operations. Automating financial processes is one of the most effective strategies to reduce costs, improve efficiency, and minimize errors. Repetitive and manual tasks, such as accounting, invoicing, and payment management, often consume valuable time and are prone to human error. Implementing financial automation tools can transform these processes, allowing companies to save time and money while improving the accuracy of their data.</p>
        <p class="mb-4 text-muted-foreground">Financial automation not only speeds up processes but also ensures greater accuracy in transactions and reporting. This is crucial in the financial world, where decision-making depends on correct and timely data. Thanks to the integration of advanced technologies, such as artificial intelligence (AI), companies can optimize their workflows and also learn from historical patterns to predict and adapt processes intelligently. This translates into increased operational efficiency and a better ability to respond to changes in the market and regulations.</p>
        <p class="mb-4 text-muted-foreground">A notable example of automation with AI in the financial sector is Mantis, a company specializing in process optimization through artificial intelligence. Mantis helps companies eliminate manual and repetitive tasks, saving over 60% in operating costs. By implementing intelligent solutions, Mantis not only improves internal efficiency but also reduces the risk of human error, leading to a more reliable and profitable workflow. Automation with Mantis offers a crucial competitive advantage, especially in markets where speed and accuracy are fundamental.</p>
        <p class="mb-4 text-muted-foreground">In summary, automating financial processes through artificial intelligence is key to improving productivity, reducing costs, and increasing competitiveness. Companies that implement solutions like those offered by Mantis can optimize their operations, make data-driven decisions, and quickly adapt to market demands. By integrating financial automation tools, companies not only reduce their costs but also improve the quality of their services and strategic decision-making.</p>
      `,
    },
  },
};

const translations = {
  en: {
    nav: {
      features: "Features",
      workflow: "Workflow",
      security: "Security",
      pricing: "Pricing",
      contact: "Contact",
      login: "Login",
      startFreeTrial: "Start Free Trial",
      blog: "Blog",
    },
    footer: {
      product: "Product",
      company: "Company",
      resources: "Resources",
      legal: "Legal",
      aboutUs: "About Us",
      careers: "Careers",
      blog: "Blog",
      documentation: "Documentation",
      support: "Support",
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      cookiePolicy: "Cookie Policy",
      copyright: "© 2024 Mantis AI. All rights reserved.",
    },
  },
};

export default function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = blogPosts[id];
  const t = translations.en;

  if (!post) {
    return <div>Loading...</div>;
  }

  const language = "en"; // or 'es' based on user selection
  const selectedPost = {
    title: post.title[language],
    date: post.date,
    content: post.content[language],
  };

  return (
    <div className="min-h-screen bg-background">
      <Navbar t={t} />
      <main className="container mx-auto px-4 py-12 pt-24">
        <button onClick={() => navigate(-1)} className="flex items-center mb-4">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Blog
        </button>
        <article className="prose prose-lg dark:prose-invert mx-auto">
          <h1 className="text-4xl font-bold mb-4">{selectedPost.title}</h1>
          <p className="text-muted-foreground mb-8">{selectedPost.date}</p>
          <div dangerouslySetInnerHTML={{ __html: selectedPost.content }} />
        </article>
      </main>
      <Footer t={t} />
    </div>
  );
}
