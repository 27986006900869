import React, { useState } from "react";
import { Button } from "./components/ui/button";
import { LogIn, Menu, X } from "lucide-react";
import { ThemeToggle } from "./components/ui/ThemeToggle";
import { AnimatePresence, motion } from "framer-motion";
import mantisFavicon from "./img/favicon.ico";

function NavItem({ href, children }) {
  return (
    <a
      href={href}
      className="text-sm font-medium text-muted-foreground hover:text-foreground transition-colors"
    >
      {children}
    </a>
  );
}

function MobileNav({ isOpen, setIsOpen, t }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: "100%" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100%" }}
          transition={{ type: "spring", bounce: 0, duration: 0.4 }}
          className="fixed inset-y-0 right-0 w-full sm:max-w-sm bg-background p-6 sm:ring-1 sm:ring-gray-900/10 max-h-[15vh] overflow-y-auto"
        >
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Mantis AI</span>
              <img className="h-8 w-auto" src={mantisFavicon} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="md:flex items-center space-x-6 pt-6">
                <NavItem
                  href="/#features"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.features}
                </NavItem>
                <NavItem
                  href="/#workflow"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.workflow}
                </NavItem>
                <NavItem
                  href="/#security"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.security}
                </NavItem>
                <NavItem
                  href="/#pricing"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.pricing}
                </NavItem>
                <NavItem
                  href="/#contact"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.contact}
                </NavItem>
                <NavItem
                  href="/blog"
                  className="block py-2 text-lg font-semibold hover:text-primary transition-colors"
                >
                  {t.nav.blog}
                </NavItem>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default function Navbar({ t }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-background/80 backdrop-blur-sm border-b border-border z-50 shadow-md">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div
            className="flex items-center"
            onClick={() => {
              window.open("/", "_self");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="w-8 h-8 rounded-lg bg-primary/90 flex items-center justify-center">
              <img
                src={mantisFavicon}
                alt="Logo"
                className="w-6 h-6 object-cover"
              />
            </div>
            <span className="ml-2 text-xl font-bold">Mantis AI</span>
          </div>

          <div className="hidden md:flex items-center space-x-6">
            <NavItem href="/#features">{t.nav.features}</NavItem>
            <NavItem href="/#workflow">{t.nav.workflow}</NavItem>
            <NavItem href="/#security">{t.nav.security}</NavItem>
            <NavItem href="/#pricing">{t.nav.pricing}</NavItem>
            <NavItem href="/#contact">{t.nav.contact}</NavItem>
            <NavItem href="/blog">{t.nav.blog}</NavItem>
          </div>

          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                window.open("https://mantisapp.vercel.app", "_blank");
              }}
              className="hidden md:inline-flex"
            >
              <LogIn className="mr-2 h-4 w-4" />
              {t.nav.login}
            </Button>
            <ThemeToggle />
            <div className="md:hidden">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Menu className="h-6 w-6" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} t={t} />
    </nav>
  );
}
